<template>
    <div>

        <v-icon class="text-black"
                @click="back">mdi-arrow-left</v-icon>
        <span class="text-black font-weight-bold pl-5 size">{{ $t('Packages') }}</span>
        <v-divider color="white"
                   class="mt-5 mb-7"></v-divider>
        <v-card>
            <v-card-title class="weight">
                <div class="color mt-2 text-green">{{ $t('Packages Details') }}</div>
            </v-card-title>
            <v-row dense
                   class="mt-2">
                <v-col cols="12"
                       md="4"
                       sm="6">
                    <v-card-text>
                        <p class="font-weight-semibold mb-0 text-black">
                            {{ $t('Package Name') }}
                        </p>
                        <v-text-field dense
                                      :disabled="view"
                                      :placeholder="$t('Enter Package Name')"
                                      v-model="packageName"></v-text-field>
                    </v-card-text>
                </v-col>
                <v-col cols="12"
                       md="4"
                       sm="6">
                    <v-card-text>
                        <p class="font-weight-semibold mb-0 text-black">
                            {{ $t('Package Name Ar') }}
                        </p>
                        <v-text-field class="write-ar" dense
                                      :disabled="view"
                                      :placeholder="$t('Enter Package Name Ar')"
                                      v-model="packageNameAr"></v-text-field>
                    </v-card-text>
                </v-col>
                <v-col cols="12"
                       md="4"
                       sm="6">
                    <v-card-text>
                        <p class="font-weight-semibold mb-0 text-black">
                            {{ $t('Package Price (SAR)') }}
                        </p>
                        <v-text-field hide-details="auto"
                                      dense
                                      :disabled="view"
                                      step="0.01"
                                      @blur="restrictDecimal"
                                      :placeholder="$t('Enter Price in SAR')"
                                      v-model="packagePrice"></v-text-field>
                    </v-card-text>
                </v-col>
                <v-col cols="12"
                       md="4"
                       sm="6"
                       v-if="ownerRole">
                    <v-card-text>
                        <p class="font-weight-semibold mb-0 text-black">
                            {{ $t('Boat Manager') }}
                        </p>
                        <v-select :items="boatOwners"
                                  :placeholder="$t('Select Boat Manager')"
                                  variant="underlined"
                                  hide-details="auto"
                                  dense
                                  item-text="first_name"
                                  item-value="id"
                                  :disabled="view"
                                  class="text-fields"
                                  v-model="boatOwnerId"></v-select>
                    </v-card-text>
                </v-col>

            </v-row>

            <v-row dense>
                <v-col cols="12" lg="6">
                    <v-card-text>
                        <p class="font-weight-black text-body-2 text-black">
                            {{ $t('Package Description') }}
                        </p>
                        <v-textarea rows="3"
                                    hide-details="auto"
                                    outlined
                                    auto-grow
                                    :disabled="view"
                                    autocomplete="Category Description"
                                    :placeholder="$t('Enter Package Description')"
                                    v-model="packageDescription">
                        </v-textarea>
                    </v-card-text>
                </v-col>
                <v-col cols="12" lg="6">
                    <v-card-text>
                        <p class="font-weight-black text-body-2 text-black">
                            {{ $t('Package DescriptionAr') }}
                        </p>
                        <v-textarea class="write-ar" rows="3"
                                    hide-details="auto"
                                    outlined
                                    auto-grow
                                    :disabled="view"
                                    autocomplete="Category DescriptionAr"
                                    :placeholder="$t('Enter Package DescriptionAr')"
                                    v-model="packageDescriptionAr">
                        </v-textarea>
                    </v-card-text>
                </v-col>
            </v-row>

            <v-row dense>
                <v-col cols="12">
                    <v-card-text>
                        <p class="font-weight-black text-body-2 text-black">{{ $t('Upload Image') }}</p>
                        <div class="upload-docu mr-3"
                             style="padding:0px !important"
                             tile>
                            <input type="file"
                                   name="file"
                                   id="packageDocument"
                                   class="hidden-input"
                                   ref="filePackage"
                                   @change="uploadImg2"
                                   hidden
                                   accept=".pdf,.jpg,.jpeg,.png" />
                            <v-img v-if="packageImage"
                                   :src="packageImage"
                                   width="100%"
                                   style="border-radius: 10px;height: 100%;max-height: 154px;"
                                   class="me-2"></v-img>
                            <span v-if="packageImage && !view"
                                  class="category-icon"
                                  @click="deleteImg">
                                <v-img :src="require(`@/assets/images/circle-cross.png`)"
                                       height="21px"
                                       width="22px"></v-img>
                            </span>
                            <label v-if="!packageImage"
                                   for="fileInput"
                                   class="file-label">
                                <img class="imge"
                                     :src="require(`@/assets/images/dummy-img.png`)"
                                     alt="">
                            </label>
                            <div v-if="!packageImage && !view"
                                 style="cursor: pointer;"
                                 class="upload-img owner-info-btn border-card"
                                 @click="chooseFiles2()">
                                {{ $t('Upload Image') }}
                            </div>
                        </div>
                    </v-card-text>
                </v-col>
            </v-row>

            <v-row dense>
                <v-col cols="12"
                       md="4"
                       sm="6">
                    <v-card-text>
                        <v-text-field :placeholder="$t('Enter Package Items')"
                                      variant="underlined"
                                      append-icon="mdi-plus-circle"
                                      :disabled="view"
                                      class="text-fields"
                                      @click:append="addPackage"
                                      v-model="packageItem"></v-text-field>
                    </v-card-text>
                </v-col>
            </v-row>

            <v-row dense>
                <v-col cols="12">
                    <v-card-text>
                        <p class="font-weight-black text-body-2 text-black">
                            {{ $t('Added Package Items') }}
                        </p>
                        <div class="package-item mt-3">
                            <v-chip small
                                    v-for="( field, index ) in  packageItems"
                                    :key="index"
                                    :disabled="view"
                                    label
                                    close
                                    class="mr-3 mt-1"
                                    @click:close="remove(index)"
                                    style="background-color: #F3F3F3; color: black">
                                <span style="font-size: 15px;">{{ field }} &nbsp;</span>
                            </v-chip>
                        </div>
                    </v-card-text>
                </v-col>
            </v-row>

            <v-card-actions v-if="!view">
                <v-btn class="owner-info-btn border-card text-capitalize font-weight-regular rounded-lg px-9 ml-1 white--text"
                       color="#00B2A9"
                       @click="addNewPackage">
                    {{ $t('Add Package') }}
                </v-btn>
            </v-card-actions>
        </v-card>

    </div>
</template>

<script>
import router from '@/router';
import packageServices from '@/services/package-services';
import userServices from '@/services/user-services';
import config from '/config';

import darkMode from '@core/utils/modalColor';

import { mdiClose } from '@mdi/js';

export default {
    data: () => ({
        mdiClose: mdiClose,
        packageImage: null,
        packageDescription: null,
        packageDescriptionAr: null,
        packagePrice: null,
        packageItem: '',
        packageItems: [],
        boatOwners: [],
        packageName: null,
        packageNameAr: null,
        ownerId: null,
        boatOwnerId: null,
        view: false,
        packageFile: null,
        ownerRole: true

    }),

    mounted()
    {
        const userData = JSON.parse(localStorage.getItem('userData'));
        // console.log(userData);
        if (userData.role == 'boat_owner')
        {
            this.ownerRole = false;
            this.boatOwnerId = userData.id;
        }
        let data = {
            role: 'boat_owner'
        }
        userServices.getOwnerManagerList(data).then(resp =>
        {
            // console.log();
            if (resp.statusCode == 200)
            {
                this.boatOwners = resp.data.users

                // this.boatOwners.sort((a, b) => a.first_name - b.first_name);
                // // console.log(this.boatOwners);
                this.boatOwners.sort(function (a, b)
                {
                    if (a < b)
                    {
                        return -1;
                    } else if (a > b)
                    {
                        return 1;
                    } else
                    {
                        return 0;
                    }
                });
            }
        })

        if (router.currentRoute.params.id)
        {
            this.packageId = router.currentRoute.params.id
            this.type = router.currentRoute.params.type
            if (this.type == 'view')
            {
                this.view = true;
            }
            this.getPackageData();
        }
    },
    methods: {
        restrictDecimal()
        {
            // Parse the input value as a number
            const value = parseFloat(this.packagePrice);

            // Check if the parsed value is a valid number
            if (!isNaN(value))
            {
                // Format the value to 2 decimal places
                const formatted = value.toFixed(2);

                // Update the formattedValue data property
                this.formattedValue = formatted;
                this.packagePrice = this.formattedValue;
            } else
            {
                // Clear the formattedValue if the input is not a valid number
                this.formattedValue = '';
            }
        },
        back()
        {
            router.push('/package/list')
        },
        getPackageData()
        {
            packageServices.getsinglePackage(this.packageId).then(res =>
            {
                if (res.statusCode == 200)
                {
                    this.packageName = res.data.boat_owner_packages[0].name;
                    this.packageNameAr = res.data.boat_owner_packages[0].name_ar;
                    this.boatOwnerId = res.data.boat_owner_packages[0].boat_owner_id;
                    this.packageDescription = res.data.boat_owner_packages[0].description;
                    this.packageDescriptionAr = res.data.boat_owner_packages[0].description_ar;
                    this.packagePrice = res.data.boat_owner_packages[0].price;

                    this.restrictDecimal();

                    if (res.data.boat_owner_packages[0].image)
                    {
                        this.packageImage = config.MYBOAT_IMG_URL + res.data.boat_owner_packages[0].image
                    }
                    else
                    {
                        this.packageImage = null
                    }

                    this.packageItems = res.data.boat_owner_packages[0].tags.split(",");
                }
            })
        },
        addNewPackage()
        {
            let endpoint = 'create';
            let successMsg = 'Package has been added successfully.';

            const formData = new FormData();
            formData.append('name', this.packageName);
            formData.append('name_ar', this.packageNameAr);
            formData.append('description', this.packageDescription);
            formData.append('description_ar', this.packageDescriptionAr);
            formData.append('price', this.packagePrice);
            formData.append('tags', this.packageItems.join());
            formData.append('boat_owner_id', this.boatOwnerId);
            formData.append('package_image', this.packageFile);

            if (this.packageId)
            {
                formData.append('id', this.packageId);
                successMsg = 'Package has been updated successfully.';
                endpoint = 'update';
            }
            packageServices.postPackage(formData, endpoint).then(resp =>
            {
                if (resp.statusCode == 200)
                {
                    this.$swal({
                        // title: this.type,
                        text: successMsg,
                        confirmButtonColor: darkMode.getTextColor(),
                        customClass: darkMode.getBackgroundColor(),
                        icon: 'success',
                    }).then((result) =>
                    {
                        router.push({ name: 'package-list' });
                    });
                }
                else
                {
                    this.$swal({
                        icon: 'error',
                        title: 'User',
                        confirmButtonColor: darkMode.getTextColor(),
                        customClass: darkMode.getBackgroundColor(),
                        text: 'Something went wrong!',
                    })
                }
            });
        },

        addPackage()
        {

            if(this.packageItem == undefined || this.packageItem == '')
            {
                return;
            }
            if (this.packageItems.length < 5 && !this.packageItems.includes(this.packageItem))
            {
                if (this.packageItem.includes(","))
                {
                    let value = this.packageItem.split(",");

                    value.forEach(element =>
                    {
                        if (this.packageItems.length < 5 && !this.packageItems.includes(element))
                        {
                            this.packageItems.push(element);
                        }
                    });
                    this.packageItem = '';
                }
                else
                {
                    if (this.packageItems.length < 5)
                    {
                        this.packageItems.push(this.packageItem);
                    }
                    this.packageItem = '';
                }
            }
        },

        remove(index)
        {
            this.packageItems.splice(index, 1);
        },

        uploadImg2(event)
        {

            // console.log(this.formFields);
            let file = event.target.files[0];
            this.packageFile = event.target.files[0];
            // console.log(file);
            return new Promise((resolve, reject) =>
            {

                let fileSize = event.target.files[0].size / 1024 / 1024;

                const reader = new FileReader();
                reader.readAsDataURL(event.target.files[0]);
                reader.onload = () =>
                {
                    // this.image = reader.result;
                    this.packageImage = reader.result;
                    // let base64Image = reader.result;
                    // this.packageFile = base64Image.replace(/^data\:([^\;]+)\;base64,/gmi, '');
                    // // console.log(this.packageFile);
                    // this.uploadcardLimitImage(base64Image, file.size, file.name)

                };
            })
        },

        chooseFiles2: function ()
        {
            document.getElementById("packageDocument").click()
        },

        chooseFiles1: function (index)
        {
            this.fileIndex = index;
            document.getElementById("fileUpload1").click()
        },

        deleteImg()
        {
            this.packageImage = null;
            this.packageFile = null;
        },
    }
}
</script>

<style scoped>
.weight {
    font-weight: 600;
}

.color {
    color: #00B2A9;
}

.size {
    font-size: 22px;
}

.text-black {
    color: #000;
}

.upload-docu {
    color: rgb(255, 255, 255);
    align-items: center;
    background: #F9F9F9;
    backdrop-filter: blur(7px);
    border-radius: 10px;
    padding: 20px 0px;
    height: 150px;
    width: 165px;
    border: 1px dashed #B1B1B1;
}

.package-item {
    border-radius: 5px;
    border: 1px solid #DADADA;
    border-radius: 12px;
    padding: 12px 15px;
}

.upload-img {
    background-color: #00B2A9 !important;
    /* border: 3px solid; */
    padding: 6px;
    border-radius: 10px;
    /* color: white; */
    text-align: center;
    width: 90px;
    font-size: 11px;
    margin: 82px 38px 0px;
}

.imge {
    height: 20px;
    width: 22px;
    margin: 0px 69px -49px;
}

.category-icon {
    position: absolute;
    top: 7px;
    right: 12px;
    background: white;
    width: 34px;
    height: 33px;
    padding: 6px;
    border-radius: 30px;
    border-bottom-left-radius: 30px !important;
    border-bottom-right-radius: 30px !important;
}
</style>



